import React from "react";
import { Button } from "@ds-proxy";
import { Icon, IconProps } from "@ds-ui";

type AvailableProviders = "google" | "saml" | "password";

const icons: Record<AvailableProviders, IconProps["name"]> = {
  google: "Google",
  saml: "BusinessOutlined",
  password: "PermIdentityOutlined",
};

export interface ProviderButtonProps {
  type: AvailableProviders;
  label: string;
  onClick: () => void;
}

export function ProviderButton(props: ProviderButtonProps) {
  return (
    <Button size="large" variant="outlined" onClick={props.onClick} color="primary">
      <div
        style={{
          display: "flex",
          gap: "var(--spacing-m)",
          width: "100%",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          alignItems: "center",
          color: "var(--color-text-primary)",
        }}
      >
        <Icon
          name={icons[props.type]}
          style={{ fontSize: 20, width: 20, height: 20, minWidth: 20 }}
        />
        <span>{props.label}</span>
      </div>
    </Button>
  );
}
